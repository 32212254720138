.container {
  max-width: $body-width;
  background-color: $light-sky-blue;
  //overflow: hidden;
  padding: 0 !important;
  //min-height: 100vh;
}
.container-waystone {
  background-color: #d0cfcd;
  padding-bottom: 5rem !important;
}
.heading-for-waystone {
  color: #b86746;
}
.box-shadow {
  border-radius: 8px;
  border: 1px solid $light-sky-blue;
}

.container-to-show-parallax {
  overflow: hidden;
}
// hero-section
.hero-background-container {
  padding-top: 0px;
  min-height: 100vh;
}
.hero-mob-top-cloud {
  position: absolute;
  right: -10px;
  top: 40px;
}

.hero-mob-bottom-cloud {
  position: absolute;
  right: -30px;
  bottom: 80px;
}

.hero-mob-mid-cloud {
  position: absolute;
  width: 578px;
  top: 154px;
  height: 122px;
  @media (min-width: 578px) {
    right: 0;
  }
}

.hero-mob-mountains {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 127px;
}

.hero-mob-image-container {
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

.glassmorphism-container {
  @media screen and (min-width: 768px) {
    background: rgba(202, 234, 239, 0.5);
    backdrop-filter: blur(3px);
    border-radius: 10px;
    // border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.hero-mob-hills {
  position: absolute;
  left: 0;
  bottom: 0;
}

.logo-image {
  margin-top: 10rem;
  width: 100px;
  height: 50px;
  @media (min-width: 768px) {
    margin-top: 2rem;
    width: 250px;
    height: 120px;
  }
}

.content-container {
  position: relative;
  overflow: hidden;
}

.hero-container {
  position: absolute;
  top: 10%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    display: inline-block;
    width: 100%;
    position: relative;
  }
}

.explore-arrow-img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.explore-arrow-imge {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

//projections-section
.input-container > .form-control.form-control-solid {
  color: $input-text !important;
  font-family: 'Lexend' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  background-color: $white !important;
  border: 0 !important;
  min-height: calc(1em + 1rem + 1px);
  padding: 0.775rem 1.5rem 0.775rem 0 !important;
}

.projections-select > .form-select {
  color: $white !important;
  font-family: 'Lexend' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  border-radius: 8px !important;
  border-color: $select-bg !important;
   background-color: $select-bg !important;
  background-size: 7px 5px; /* Adjust the size as needed */
  background-image: url('../../../../../../public/media/home/vector.svg') !important;
  min-height: calc(1em + 1rem + 1px);
  padding: 0.775rem 2rem 0.775rem 1rem;
}
.stress-test-dropdown > .form-select {
  color: $white !important;
  font-family: 'Lexend' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  border-radius: 8px !important;
  // border-color: $select-bg !important;
  background-size: 7px 5px; /* Adjust the size as needed */
   background-image: url('../../../../../../public/media/home/vector.svg') !important;
  min-height: calc(1em + 1rem + 1px);
  padding: 0.775rem 2rem 0.775rem 1rem;
}
.input-container {
  padding-left: 1rem;
  border-radius: 8px !important;
  background-color: $white !important;
  border: 1px solid $input-border !important;
}

.fields-label {
  color: $select-bg !important;
  white-space: nowrap;
  font-size: 10.125px;
  line-height: 22.5px;
  @media (min-width: 576px) {
    font-size: 12px;
    line-height: 25.313px;
  }
}

.currency-symbol {
  color: $input-text !important;
  font-weight: 300 !important;
  font-size: 12px;
}

.tooltip-logo {
  width: 11px;
}

.tooltip-lg-logo {
  width: 19px;
}

.time-frame {
  color: transparent !important;
}

.worth-mobile-text {
  color: $teal-blue;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 35px;
  }
  @media screen and (min-width: 992px) {
    font-size: 30px;
    line-height: 38px;
  }
}

.form.sticky {
  position: sticky;
  top: 0px !important;
  //background-color: rgba(215, 218, 218, 0.541);
  background-color: $light-sky-blue;
  z-index: 20;
  width: '100%';
  /* Additional styles as needed */
}
//projections-table

[data-column-id='initial-deposit'].rdt_TableCol {
  background-color: $quaternary;
  color: $white;
}

[data-column-id='gains'].rdt_TableCol {
  background-color: $senary;
}
[data-column-id='total-gains'].rdt_TableCol {
  background-color: $senary;
}
[data-column-id='contributions'].rdt_TableCol {
  background-color: $quinary;
  color: $white;
}
[data-column-id='total-contributions'].rdt_TableCol {
  background-color: $quinary;
  color: $white;
}
[data-column-id='flip-gain'].rdt_TableCol {
  background-color: $senary;
}
[data-column-id='rental-income'].rdt_TableCol {
  background-color: $senary;
}

// sbloc table cells
[data-column-id='sbloc-draw'].rdt_TableCol {
  background-color: $borrow;
  color: $white;
}
[data-column-id='sbloc-prior-balance'].rdt_TableCol {
  background-color: $borrow;
  color: $white;
}
[data-column-id='sbloc-int-rate'].rdt_TableCol {
  background-color: $borrow;
  color: $white;
}
[data-column-id='sbloc-balance-eoy'].rdt_TableCol {
  background-color: $senary;
}
[data-column-id='sbloc-util'].rdt_TableCol {
  background-color: $senary;
}

//projections-chart
.loss-bar {
  fill: $tertiary;
}

.gain-bar {
  fill: $senary;
}

.initial-deposit-bar {
  fill: $quaternary;
}
.contribution-bar {
  fill: $quinary;
}

.custom-tick-label {
  font-family: 'Lexend';
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (min-width: 768px) {
    font-size: 11.8px;
  }
  @media screen and (min-width: 546px) and (max-width: 767px) {
    font-size: 9.5px;
  }
}

.custom-tick-label-custom {
  font-weight: bold;
}
/* CSS */
.goal-text-container {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflowed text */
  text-overflow: ellipsis; /* Display ellipsis when text overflows */
  max-width: 1%; /* Optionally, set a max-width to limit the container width */
}

.custom-label {
  font-family: 'Lexend';
  font-size: 10px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  @media screen and (min-width: 546px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.chart-tree-img {
  width: 100%;
  height: 300px;
  background-color: $white;
}

.chart-tree-container {
  height: 300px;
}

.chart-button-below-tree {
  position: absolute;
  bottom: 12px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}

.download-button-container {
  top: 3rem;
  left: 3rem;
}
.custom-tooltip {
  background-color: $white;
  padding: 12px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid $teal;
}

.sbloc-tooltip-container {
  width: 20rem !important;
}
.gray-text {
  color: $gray-600-dark !important;
}
.label-loss {
  color: $tertiary !important;
}
.label-gain {
  color: $senary !important;
}
.label-black {
  color: $black !important;
}
.label-brown {
  color: $label-brown !important;
}
.label-contributions {
  color: $quinary !important;
}
.label-initial-deposit {
  color: $quaternary !important;
}
.label-borrow {
  color: $borrow !important;
}
.label-re-invest {
  color: $gain-on-re-invest !important;
}
.label-bold {
  font-weight: 600 !important;
  letter-spacing: 0.5px;
}

.label-main {
  color: $teal-blue !important;
}
.download-btn-container {
  border: 2px solid #d80ed8;
  border-radius: 8px;
  padding: 2px;
}
.download-btn-img {
  width: 20px;
  height: 20px;
}
//lifestyle-section
.cloud-top-small {
  left: 340px;
  top: 45px;
}

.cloud-left-top {
  left: 25px;
  top: 150px;
}

.cloud-left-bottom-small {
  left: 30px;
  top: 620px;
}

.cloud-left-bottom-big {
  height: 120px;
  left: 155px;
  top: 545px;
}

.cloud-right-bottom-small {
  right: 255px;
  bottom: 20px;
}

.cloud-right-bottom-big {
  position: absolute;
  right: 0;
  // top: 753px;
  bottom: 0;
}

.bird-house-img {
  width: 260px;
  position: relative;
  @media screen and (min-width: 768px) {
    width: 380px;
  }
  @media screen and (min-width: 992px) {
    margin-top: 5rem;
    width: 450px;
  }
}

// beginners-section
.cloud-big {
  position: absolute;
  left: 0;
  top: 116px;
  z-index: -1;
}

.cloud-small {
  position: absolute;
  right: 54.5px;
  top: 125px;
  z-index: -1;
}

.cloud-about-big {
  position: absolute;
  left: 0;
  top: 116px;
  z-index: 1;
}

.cloud-about-small {
  position: absolute;
  top: 210px;
  right: 20px;
  z-index: 0;
}

.cloud-about-large {
  position: absolute;
  top: 250px;
  left: 0;
  z-index: 0;
}

.hard-concept-gif-img {
  width: 100%;
  @media screen and (min-width: 992px) {
    height: 100%;
    width: 100%;
  }
}

.mission-text {
  width: 780px;
}

.our-values-container {
  min-height: 35rem;
}

.watering-plants-img {
  width: 220px;
  height: 125px;
  @media screen and (min-width: 575px) {
    width: 320px;
    height: 190px;
  }
  @media screen and (min-width: 768px) {
    width: 430px;
    height: 240px;
  }
  @media screen and (min-width: 992px) {
    width: 525px;
    height: 250px;
    top: 390px;
    right: 160;
  }
}

.our-values-img {
  width: 300px;
  height: 90px;
  @media screen and (min-width: 575px) {
    width: 390px;
    height: 160px;
  }
  @media screen and (min-width: 768px) {
    width: 440px;
    height: 190px;
  }
  @media screen and (min-width: 992px) {
    width: 540px;
    height: 160px;
    top: 555px;
    left: 140px;
  }
}

.our-values-text {
  top: 510px;
  left: 240px;
}

// stay tuned for the experience - section
.stay-tuned-bg {
  background: #d9d9d9;
}

.stay-tuned-cloud-right {
  width: 636px;
  height: 213px;
  right: -32px;
  top: 20px;
}

.stay-tuned-cloud-left {
  width: 579px;
  height: 210px;
  left: -90px;
  bottom: -23px;
}

.stay-tuned-cloud-left-image {
  width: 579px;
  height: 210px;
  left: -90px;
  bottom: -23px;
  @media screen and (min-width: 1200px) {
    bottom: 45px;
  }
}

.stay-tuned-ellipse-right {
  top: 240px;
  right: 0;
}

.stay-tuned-ellipse-left {
  position: absolute;
  top: 220px;
  right: 365px;
}

.stay-tuned-bird-right-image {
  right: 20px;
  top: 250px;
  z-index: -1;
}

.stay-tuned-bird-left {
  left: 145px;
  top: 365px;
}

.stay-tuned-path {
  position: absolute;
  z-index: -1;
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 230px;
}
.stay-tuned-join {
   margin-top: 3rem;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    margin-top: 2rem;
  }
}

.stay-tuned-join-container {
   margin-top: 3rem;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  @media screen and (min-width: 1200px) {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

.waitlist-input {
  border: 2px solid $secondary !important;
  border-radius: 8px !important;
}

.waitlist-input input {
  background-color: rgba(255, 255, 255, 0.5) !important;
  padding-left: 1.2rem !important;
  min-height: 10px !important;
}

// investment section
.investment-container {
}

.investment-text-container {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 75%;
  }
}

.investment-img {
  width: 219px;
  height: 135px;
}

.rock-tress-img {
  top: 50px;
  right: 0;
  width: 200px;
  @media screen and (min-width: 768px) {
    top: 15px;
    width: 304px;
    height: 227px;
  }
}

// slider
.slider-images-container {
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
}

/* Main scrollbar styles */
.slider-container ::-webkit-scrollbar {
  // height: 0.4rem;
  @media screen and (min-width: 768px) {
    height: 2rem;
    background-color: transparent !important;
  }
}

.slider-container ::-webkit-scrollbar-thumb {
  background-color: $primary !important;
  @media screen and (min-width: 768px) {
    background-image: url('../../../../../../public/media/home/slider-thumb.svg'),
      linear-gradient(100deg, transparent, transparent);
    background-position: center bottom, center;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
    background-color: transparent !important;
  }
}

.slider-container ::-webkit-scrollbar-thumb:hover {
  @media screen and (min-width: 768px) {
    background-color: transparent !important;
  }
}

.slider-container ::-webkit-scrollbar-track {
  @media screen and (min-width: 768px) {
    background-color: transparent !important;
  }
}

.slider-container ::-webkit-scrollbar-track:hover {
  @media screen and (min-width: 768px) {
    background-color: transparent !important;
  }
}

.slider-container li:last-of-type {
  margin: 0;
}

.slider-div {
  top: 186px;
  left: 0;
  right: 0;
  z-index: -1;
  border-bottom: 1px solid white;
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    top: 187px;
  }
}

// react-slider
.slider {
  margin-top: 5px;
  height: 3px;
  background-color: #4d4d4d !important;
  border-radius: 3px;
}

.thumb {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .slider-div {
    display: none;
  }
}

//same math,new look section
.image-container {
  @media screen and (max-width: 768px) {
    width: 55%;
  }
}

.customize-text {
  text-align: center;
  @media screen and (min-width: 992px) {
    text-align: right;
  }
}

// financial section
@import '_financial-section';

.poweredBy-text {
  position: fixed;
  font-size: x-small;
  bottom: 0px;
  right: 0px;
}

// advisor images
.advisor-image {
  object-fit: contain;
}

// pictograph section
.pictograph-range .form-range {
  &::-webkit-slider-thumb {
    width: 1rem; // Apply the width to the thumb
    height: 1rem; // Apply the height to the thumb
    border-radius: 0.5rem;
  }
  &::-webkit-slider-runnable-track {
    background-color: $white;
  }
}

.pictograph-custom-image {
  cursor: pointer;
  border: 5px solid transparent;
}

.selected-image-bg-color {
  border: 5px solid $primary;
}

.selected-image-tick {
  top: -6px;
  right: 2px;
}

.italic-text {
  font-style: italic;
}

.pl-0 {
  padding-left: 0px;
}

.w-90 {
  width: 92%;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-8 {
  font-size: 8px;
}

.mt-200 {
  margin-top: 220px;
}

.mt-n200 {
  margin-top: -250px;
}

.-mt-20 {
  margin-top: -20px;
}

.mt-150 {
  margin-top: 150px;
}

.sticky-header {
  position: fixed;
  top: 0;
  margin: 0 !important;
  width: 100%;
  background: $light-sky-blue; /* Or any appropriate background */
  z-index: 10; /* Or as needed to be above other content */
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
}

.snapping-container {
  /* Enable vertical snapping */
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  scroll-behavior: smooth;

  /* Hide scrollbar for Webkit & Blink browsers like Safari, Chrome, and newer versions of Edge */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.h-100vh {
  height: 100vh;
}

.h-110vh {
  height: 105vh;
  @media screen and (min-width: 575px) {
    height: 110vh;
  }
}

.w-80 {
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    width: 70%;
  }
}

.snap-section {
  /* Each section will snap align to the start */
  scroll-snap-align: start;

  /* Optional styling for better visualization */
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.date-picker-custom-styles {
  color: $input-text !important;
  font-family: 'Lexend' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  background-color: $white !important;
  border: 0 !important;
  min-height: calc(1em + 1rem + 1px);
  padding: 0.775rem 1.5rem 0.775rem 0 !important;
}

.custom-select-styles > .css-1nmdiq5-menu {
  z-index: 3 !important;
  border: 0 !important;
}

.custom-select-styles > .css-13cymwt-control {
  border-radius: 8px !important;
  border-color: transparent !important;
}

.custom-select-styles > .css-t3ipsp-control {
  border-radius: 8px !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.custom-select-stylesr > .css-t3ipsp-control:hover {
  border-radius: 8px;
  border-color: transparent !important;
}
.waystone-slider-color {
  color: #00202d !important;
}

.custom-slider .rs-slider-handle:hover {
  box-shadow: none !important;
}


.primary-sky-blue-component {
  background-color: $light-sky-blue;
}
.mt-100{
  margin-top:100px
}

.hide-scroll-bar {
  /* Hide scrollbar for WebKit browsers (Chrome, Safari, and Opera) */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dropdown-bg-color > .form-select {
  background-color: $dark-beige !important;
  border-color: $dark-beige !important;
}

// .sbloc-data-table-custom .ikexHN {
//   min-width: 90px !important;
// }

// .sbloc-data-table-custom .rPOCv {
//   min-width: 90px !important;
// }

.persona-custom-dropdown {
  position: relative;
  display: inline-block;
}

.persona-dropdown-button {
  padding-block: 8px;
  padding-inline: 1rem;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 16rem;
  background-color: $dark-beige !important;
  color: white;
  background-size: 7px 5px; /* Adjust the size as needed */
  background-image: url('../../../../../../public/media/home/vector.svg') !important;
}

.persona-dropdown-list {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
  background: white;
  z-index: 100;
}

.persona-dropdown-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
}

.persona-dropdown-item button {
  margin-left: 10px;
}

.persona-dropdown-item > span {
  flex: 1
}