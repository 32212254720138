.feature-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  gap: 15px;
  padding: 10px; /* Add padding to the container for spacing */

  .suggested-feature {
    flex-basis: calc(33.33% - 10px); /* Calculate the width for three elements with padding */
    margin-bottom: 10px; /* Add margin for spacing between rows */
    padding: 10px;
    border: 1px solid $secondary;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $secondary;
    }

    &.selected {
      background-color: $white;
      color: $secondary;
      border: 1px solid $primary;
    }
  }
}

.feature-suggestion-cloud-right {
  width: 636px;
  height: 213px;
  right: -32px;
  top: 0;
}

.feature-suggestion-cloud-left {
  width: 579px;
  height: 210px;
  left: -90px;
  bottom: -23px;
}
