* {
  font-family: $font-family;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h1 {
  color: $primary;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    font-size: 35px;
    line-height: 45px;
  }
  @media screen and (min-width: 992px) {
    font-size: 45px;
    line-height: 56px;
  }
}

h2 {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $secondary;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 35px;
  }
  @media screen and (min-width: 992px) {
    font-size: 30px;
    line-height: 38px;
  }
}

h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: $secondary;
  @media screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 50px;
  }
}

p {
  color: $teal-blue;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 1.2rem;
  white-space: pre-line;
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
}

a {
  color: $link-color;
}

.pink-text {
  color: $secondary !important;
}

.teal-text {
  color: $teal-blue !important;
}

.primary-text {
  color: $primary !important;
}
.font-family-helvetica {
  font-family: Helvetica;
}
.font-family-montserrat {
  font-family: 'Montserrat', Helvetica;
}
.sub-heading-small-black {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: $teal-blue;
  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media screen and (min-width: 992px) {
    font-size: 25px;
    line-height: 32px;
  }
}
.sub-heading {
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  color: $teal-blue;
}

.gray-text {
  color: $label-color;
}

.sub-heading-small {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: $secondary;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media screen and (min-width: 992px) {
    font-size: 27px;
    line-height: 32px;
  }
}

.black-small-sub-heading {
  color: $black;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01px;
  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.paragraph-small {
  font-size: 12px;
  line-height: 15px;
  color: $black;
}

.paragraph-x-small {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: $black;
}

.contact-us-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $contact-us;
  @media screen and (min-width: 768px) {
    font-size: 13px;
    line-height: 16px;
  }
}

.z-index--1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.box-shadow {
  box-shadow: 0px 2.25px 2.25px rgba(0, 0, 0, 0.25);
}

.error-border {
  border-color: red !important;
}
.w-90 {
  width: 90%;
}
.ml-5 {
  margin-left: 3rem !important;
}
.ml-2 {
  margin-left: 50px;
}
.time-frame-selector-container {
  display: flex;
  justify-content: center; /* This will align the 'ALL' button to the right */
}

.time-frame-buttons {
  display: flex;
  justify-content: center; /* This will center the time frame buttons */
  flex: 1; /* This will allow the buttons to take up all available space except for the 'ALL' button */
}

.time-frame-selector-container button {
  background-color: #f3efef;
  color: #6b6868;
  border: none;
  padding: 5px 15px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.time-frame-selector-container button.active {
  background-color: $primary;
  color: #f3efef;
}

.all-button {
  margin-left: 15px;
}

.parent-div {
  background-color: white;
  min-height: 670px;
}
.parent-div-for-mobile-view {
  background-color: white;
  min-height: 570px;
}

.hide {
  opacity: 0;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: 1s ease-out 0s 1 slideInFromRight;
}
